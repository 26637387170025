<template>
  <div id="app" class="min-h-screen bg-white bg-hero-leaf-subtle-white dark:(bg-gray-700 bg-hero-leaf-subtle-dark-25)">
    <Navbar v-on:title-clicked="$router.push({name: 'Home'});"/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  components: {
    Navbar,
  },
  created: function() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.$store.commit('setInstallPrompt', e);
    });
  },
  mounted: function() {
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
