import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    installPrompt: null,
  },
  mutations: {
    setInstallPrompt: (state, event) => {
      state.installPrompt = event;
    },
  },
  getters: {
    getInstallPrompt: (state) => () => {
      return state.installPrompt;
    },
  },
  actions: {
  },
  modules: {
  }
})
