<template>
  <div class="mx-auto max-w-screen-lg md:p-5">

    <!-- Description -->
    <div class="<md:text-center text-gray-600 dark:(text-gray-200) py-3 select-none">
      <span class="font-playful text-lg <md:px-5 bg-white dark:(bg-gray-700)">PDFs erstellen und bearbeiten... <span class="italic">für Miaus!</span></span>
    </div>

    <!-- Links -->
    <div class="mx-5 md:mx-0">
      <div class="bg-white dark:(bg-gray-700) p-3 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
        <!-- Images to PDF -->
        <div v-on:click="onEntryClick({type: 'route', route: {name: 'ImagesToPdf'}})" class="block select-none rounded bg-gradient-to-br from-red-200 to-yellow-200 dark:(bg-none bg-gray-600) h-32 p-2 cursor-pointer hover:(shadow-inner) group">
          <div class="flex justify-between text-black opacity-70 dark:(text-white opacity-80) border-b border-black border-opacity-10 text-2xl font-semibold font-playful group-hover:(opacity-100)">
            <span>Bilder zu PDF</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="bi bi-arrow-right text-black opacity-0 group-hover:opacity-30 transition-opacity duration-75" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>
          </div>
          <p class="text-md text-black opacity-50 dark:(text-white) p-1 md:p-2 font-playful">Mehrere Bilder hochladen und zu einem PDF zusammenfügen.</p>
        </div>
        <!-- Page Numbers -->
        <div v-on:click="onEntryClick({type: 'route', route: {name: 'PageNumbers'}})" class="block select-none rounded bg-gradient-to-br from-red-200 to-yellow-200 dark:(bg-none bg-gray-600) h-32 p-2 cursor-pointer hover:(shadow-inner) group">
          <div class="flex justify-between text-black opacity-70 dark:(text-white opacity-80) border-b border-black border-opacity-10 text-2xl font-semibold font-playful group-hover:(opacity-100)">
            <span>Seiten Nummerieren</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="bi bi-arrow-right text-black opacity-0 group-hover:opacity-30 transition-opacity duration-75" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>
          </div>
          <p class="text-md text-black opacity-50 dark:(text-white) p-1 md:p-2 font-playful">
		    PDF-Datei hochladen und Seiten manuell nummerieren.
		  </p>
        </div>

        <!-- Install PWA -->
        <div v-if="$store.getters.getInstallPrompt() != null" v-on:click="onEntryClick({type: 'install'})" class="block select-none rounded border-1 border-gray-100 bg-white dark:(bg-gray-700 border-1 border-gray-800) p-2 py-3 cursor-pointer hover:(shadow-inner) group">
          <div class="flex justify-between border-opacity-10 text-xl font-base font-playful opacity-50 group-hover:(opacity-70)">
            <span>App installieren</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="bi bi-arrow-right text-black opacity-0 group-hover:opacity-30 transition-opacity duration-75" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>
          </div>
        </div>
      </div>
    </div>

    <!--img alt="Vue logo" src="../assets/logo.png"-->
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  methods: {
    onEntryClick: async function(entry) {
      switch (entry.type) {
        case 'route':
          this.$router.push(entry.route);
          break;
        case 'install':
          this.$store.getters.getInstallPrompt().prompt();
          this.$store.getters.getInstallPrompt().userChoice.then((result) => {
            if (result.outcome === 'accepted') {
              this.$store.commit('setInstallPrompt', null);
            }
          });
          break;
      };
      
    },
  },
}
</script>

<style scoped>
#navbar__title {
  white-space: nowrap;
}

</style>
